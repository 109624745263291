import { request } from 'umi';

// 自动生成试卷
export async function generateTestPaper(payload) {
  return request('/api/exam/generateTestPaper', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 获取题表，以及考试剩余时间
export async function getExamInfo(payload) {
  return request('/api/exam/getExamInfo', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 根据考题ID获取详情
export async function getQuestionInfoById(payload) {
  return request('/api/exam/getQuestionInfoById', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 提交单个题目答题信息
export async function submitQuestion(payload) {
  return request('/api/exam/submitQuestion', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 获取当天考试次数
export async function getExamTimes(payload) {
  return request('/api/exam/getExamTimes', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 提交试卷
export async function submitPaper(payload) {
  return request('/api/exam/submitPaper', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 获取当前城市考试是否需要人脸识别
export async function getFaceAuth(payload) {
  return request('/api/exam/getFaceAuth', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 获取公告
export async function getAnnounceByCode(payload) {
  return request('/api/exam/getAnnounceByCode', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 获取我的错题
export async function getMyIncorrectList(payload) {
  return request('/api/exam/getMyIncorrectList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 获取课程习题
export async function getMExercisesList(payload) {
  return request('/api/exam/getMExercisesList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 提交模拟试卷
export async function submitMockPaper(payload) {
  return request('/api/exam/submitMockPaper', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 获取成绩
export async function getScore(payload) {
  return request('/api/exam/getScore', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
