import { request } from 'umi';

export async function getOrderRecord(payload) {
  return request('/api/record/getOrderRecord', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function orderList(payload) {
  return request('/api/order/selectOrderList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
export async function examOrder(payload) {
  return request('/api/exam/getRecord', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function printed(payload) {
  return request('/api/certificate/print', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function bjget(payload) {
  return request('/api/captcha/bj/get', {
    method: 'GET',
    params: {
      ...payload,
    },
  });
}
export async function verify(payload) {
  // 学习进度
  return request('/api/captcha/bj/verify', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
