import 'lib-flexible';
import { RequestConfig, getDvaApp, history } from 'umi';
import { notification, message, Button } from 'antd';
import Storage from './utils/Storage';
import React from 'react';
import { deepCopy } from './utils/tool';
import axios from 'axios';

const openNotification = () => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => {
      notification.close(key)
      window.location.reload()
    }}>
      刷新  
    </Button>
  );
  notification.error({
    className: "network-error-tip",
    message: '网络请求出错',
    description: '请刷新页面重试~',
    btn,
    key,
    top: 100,
    duration: null
  });
};

let cityList = [];
/* const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
}; */

export const request: RequestConfig = {
  prefix: process.env.apiUrl,
  credentials: 'include',
  errorHandler: error => {
    console.log('errorHandler=>', error)
    if (document.getElementsByClassName('network-error-tip')[0]) return false;
    openNotification()
  },
  /* errorHandler: error => {
          console.log(error)
          const { response = {} } = error;
          const errortext = codeMessage[response.status] || response.statusText;
          const { status, url } = response;

          notification.error({
              message: `请求错误 ${status}: ${url}`,
              description: errortext,
          })
      }, */
  // 自定义端口规范
  /* errorConfig: {
          adaptor: (resData) => {

              return {
                  ...resData,
                  success: resData.ok,
                  errorMessage: resData.message,
              };
          }
      }, */
  headers: {
    token: Storage.token,
  },
  /* middlewares: [
          async function middlewareA(ctx, next) {
              console.log('A before');

              await next();

              console.log('A after');
          },
          async function middlewareB(ctx, next) {
              console.log('B before');

              await next();

              console.log('B after');
          }
      ], */
  requestInterceptors: [
    (url, options) => {
      return {
        url,
        options: {
          ...options,
          headers: {
            ...options.headers,
            // 防止没有获取到最新token
            token: Storage.token,
          },
        },
        data: {
          ...options.data,
        },
      };
    },
  ],
  responseInterceptors: [
    async response => {
      const result = await response.clone().json();

      if (result.code == 201) {
        message.warning(result.msg, 1, () => {
          const pathname = window.location.pathname.indexOf('/login');
          if (pathname == -1) {
            window.location.href =
              window.location.origin +
              '/' +
              Storage.currentCity.pinyin +
              '/login';
          }
        });
      }

      return response;
    },
  ],
};
/**
 * 监听路由变化
 */
export function onRouteChange({ routes, matchedRoutes, location, action }) {
  /**
   * 设置左侧菜单选中状态
   */
  // console.log(matchedRoutes)
  if (!matchedRoutes.length) {
    history.push('/404');
    return false;
  }
  const { route, match } = matchedRoutes[matchedRoutes.length - 1];
  //console.log('route -=== ', route, match)
  if (route.meta && route.meta.sideMenuChecked) {
    getDvaApp()._store.dispatch({
      type: 'global/setCurrentMatchRoute',
      payload: route.meta.sideMenuChecked,
    });
  } else {
    getDvaApp()._store.dispatch({
      type: 'global/setCurrentMatchRoute',
      payload: '',
    });
  }

  /**
   * 设置导航信息
   */
  if (route.meta && (route.meta.title || route.meta.rightContent)) {
    getDvaApp()._store.dispatch({
      type: 'global/setRouteMeta',
      payload: route.meta,
    });
  }
  // 设置路由前缀
  if (route.meta && route.meta.prefix) {
    getDvaApp()._store.dispatch({
      type: 'global/setRoutePrefix',
      payload: route.meta.prefix,
    });

    if (
      Storage.currentCity &&
      Storage.currentCity.pinyin != route.meta.prefix
    ) {
      Storage.token = '';
      history.push(`/${route.meta.prefix}/login`);
    }

    /**
     * 第一次进入网站 Storage.oldCurrentCity 和 Storage.currentCity 是一样的
     * 当用户切换时，Storage.oldCurrentCity 存储上一个城市信息
     *  */
    const city = cityList.filter(item => item.pinyin == route.meta.prefix)[0];

    if (!Storage.oldCurrentCity) {
      Storage.oldCurrentCity = city;
    }

    if (Storage.currentCity && Storage.currentCity.pinyin != city.pinyin) {
      Storage.oldCurrentCity = Storage.currentCity;
    }

    Storage.currentCity = city;
  } else {
    getDvaApp()._store.dispatch({
      type: 'global/setRoutePrefix',
      payload: '',
    });

    if (Storage.currentCity) {
      Storage.oldCurrentCity = Storage.currentCity;
    }

    // 不知道为什么，在打开其它页面新窗口之后，这句代码会执行，导致 localstorage 中 currentCity删除，然后导致登录接口获取不到cityId
    // Storage.currentCity = '';
  }

  // 非在线考试页和验证页中 删除 localstorage 相关记录
  // debugger
  if (
    route.path.indexOf('onlineExam') == -1 &&
    route.path.indexOf('auth') == -1
  ) {
    // debugger
    Storage.faceAuthStatus = '';
    Storage.faceAuth_failedTimes = '';
    Storage.examId = '';
    Storage.examTime = '';
    Storage.lastExamQuestion = '';
  }

  // 获取cityConfig
  // Storage.currentCity 存在 并且
  // Storage.cityConfig 不存在 或者
  // Storage.cityConfig.cityId不等于Storage.currentCity.cityId
  if (location.pathname != '/') {
    if (location.pathname.indexOf("/login") > -1) return false;
    if (location.pathname == '/download') return false;
    if (location.pathname == '/jump') return false;
    if (location.pathname == '/404') return false;
    if (
      (Storage.currentCity && !Storage.cityConfig) || 
      (Storage.currentCity && Storage.cityConfig && Storage.currentCity.cityId != Storage.cityConfig.cityId)
    ) {
      getDvaApp()._store.dispatch({
        type: 'global/cityConfig',
        payload: {
          cityId: Storage.currentCity.cityId,
        },
      });
    }
  }

  // 不是在buyCourse页时删除Storage.isClickedWxPayBtn，这个条件主要用于判断是否是从微信支付页面进入的
  if (route.path.indexOf('buyCourse') == -1) {
    console.log("来到app.tsx route.path.indexOf('buyCourse') == -1 这里删除了吗")
    Storage.isClickedWxPayBtn = false;
    Storage.userCreditId = false;
    Storage.creditId = false;
  }
  if (route.path.indexOf('buyCredit') == -1) {
    Storage.isClickedWxPayBtnForBuyCredit = false;
    Storage.userCreditIdForBuyCredit = false;
    Storage.creditIdForBuyCredit = false;
  }
}

// 设置路由前缀
function setRoutePrefix(prefix, route) {
  if (route.path == '/') {
    route.path = '/' + prefix;
  } else if (route.path.length > 1) {
    if (route.path.slice(0, 1) == '/') {
      route.path = '/' + prefix + route.path;
    } else {
      route.path = '/' + prefix + route.path + '/';
    }
  }

  if (route.redirect == '/') {
    route.redirect = '/' + prefix;
  } else if (route.redirect && route.redirect.length > 1) {
    if (route.redirect && route.redirect.slice(0, 1) == '/') {
      route.redirect = '/' + prefix + route.redirect;
    } else {
      route.redirect = '/' + prefix + route.redirect + '/';
    }
  }
  // 每条路由都记录路由前缀
  if (route.meta) {
    route.meta.prefix = prefix;
  } else {
    route.meta = { prefix };
  }

  if (route.routes && route.routes.length > 0) {
    for (let i = 0; i < route.routes.length; i++) {
      setRoutePrefix(prefix, route.routes[i]);
    }
  }
}

export function patchRoutes({ routes }) {
  let oldCityRoutes = [];
  let newCityRoutes = [];
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].meta && routes[i].meta.belong == 'city') {
      oldCityRoutes.push(deepCopy(routes[i]));
      delete routes[i];
    }
  }

  for (let i = 0; i < cityList.length; i++) {
    let tempArr = [];
    for (let j = 0; j < oldCityRoutes.length; j++) {
      tempArr.push(deepCopy(oldCityRoutes[j]));
    }
    newCityRoutes.push(tempArr);
  }

  for (let i = 0; i < cityList.length; i++) {
    for (let j = 0; j < newCityRoutes[i].length; j++) {
      setRoutePrefix(cityList[i].pinyin, newCityRoutes[i][j]);
    }
  }

  for (let i = 0; i < newCityRoutes.length; i++) {
    routes.unshift(...newCityRoutes[i]);
  }
}

export async function render(oldRender) {
  axios
    .post(`${process.env.apiUrl}/api/city/list`, {})
    .then(function(res) {
      if (res.status == 200 && res.data.code == 0 && res.data.data) {

        res.data.data.map(item => {
          // isMobileOpened: true 时才注册路由
          if (item.citys && item.citys.length > 0 && item.isMobileOpened) {
            for(var i=0; i<item.citys.length; i++) {
              if (item.citys[i].isMobileOpened) {
                cityList.push(item.citys[i])
              }
            }
          }
        })

        oldRender();
        
        getDvaApp()._store.dispatch({
          type: 'global/setCityList',
          payload: {
            cityList,
            provinceList: res.data.data
          },
        });
      }
    })
    .catch(function(error) {
      console.log(555, error);
      openNotification()
    });
}
