import { getDvaApp } from 'umi';
import {
  timeline,
  cityConfig,
  cityCredit,
  appDownloadUrl,
  getProviceCity
} from '@/services/global';
import Storage from '@/utils/Storage';
export default {
  namespace: 'global',
  state: {
    // 路由前缀
    routePrefix: '',
    // 省份列表
    provinceList: [],
    // 城市列表
    cityList: [],
    sideMenuChecked: '',
    navbar: {
      icon: 'arrow', // menu  arrow
      title: '',
      rightContent: '',
    },
    defaultYear: Storage.defaultYear || '',
    // 城市设置的时间线
    timeline: {},
    // 不在继教教育开发时间内禁止使用
    isForbid: false, // 默认不禁止
    isForbidReason: '', // 禁止的原因 notStart (未开始)  end (已结束)
    // 过了缴费截止日期禁止支付
    isForbidPay: false, // 默认不禁止
    isForbidPayReason: '', // 禁止支付的原因 notStart (未开始)  end (已结束)
  },
  effects: {
    *timeline({ payload }, { call, put }) {
      const res = yield call(timeline, payload);
      if (res.code == 0) {
        yield put({
          type: 'setTimeline',
          payload: res.data || {},
        });
      }
      return res;
    },
    *cityConfig({ payload }, { call, put }) {
      const res = yield call(cityConfig, payload);
      if (res.code == 0) {
        Storage.cityConfig = res.data;
      }
      return res;
    },
    *cityCredit({ payload }, { call, put }) {
      const res = yield call(cityCredit, payload);

      return res;
    },
    *appDownloadUrl({ payload }, { call, put }) {
      const res = yield call(appDownloadUrl, payload);

      return res;
    },
    *getProviceCity({ payload }, { call, put }) {
      const res = yield call(getProviceCity, payload);

      return res;
    },
  },
  reducers: {
    setCurrentMatchRoute(state, { payload }) {
      return {
        ...state,
        sideMenuChecked: payload,
        navbar: {
          ...state.navbar,
          icon: payload ? 'menu' : 'arrow',
        },
      };
    },

    setRouteMeta(state, { payload }) {
      const {learningMode} = Storage.cityConfig || {}
      const isFromBuyCourse =
        location.href.indexOf('/buyCourse') != -1 ? true : false;
      // const isPurchasedCourse = getDvaApp()._models.filter(item => item.namespace == 'course')[0].state.isPurchasedCourse;
      // console.log('getDvaApp ==== ', getDvaApp()._models.filter(item => item.namespace == 'course')[0].state.isPurchasedCourse )
      if (isFromBuyCourse) {
        if (state.isForbidPay) {
          state.navbar.title = learningMode == 2 ? '选课缴费' : '支付继续教育';
          state.navbar.rightContent = '';
        } else {
          state.navbar.title = learningMode == 2 ? '选课缴费' : '网上选课';
          state.navbar.rightContent = payload.rightContent;
        }
      } else {
        state.navbar.title = payload.title;
        state.navbar.rightContent = payload.rightContent;
      }

      return {
        ...state,
        sideMenuChecked: payload.sideMenuChecked,
        navbar: {
          ...state.navbar,
          icon: payload.sideMenuChecked ? 'menu' : 'arrow', // 如果当前路由元中包含 sideMenuChecked 属性 则显示面包屑
          // title: payload.title,
          // rightContent: payload.rightContent
        },
      };
    },
    /* 设置路由前缀(当前城市) */
    setRoutePrefix(state, { payload }) {
      return {
        ...state,
        routePrefix: '/' + payload,
      };
    },
    /* 设置城市列表 */
    setCityList(state, { payload }) {
      return {
        ...state,
        cityList: payload.cityList,
        provinceList: payload.provinceList
      };
    },
    /* 默认年 */
    setDefaultYear(state, { payload }) {
      Storage.defaultYear = payload;
      return {
        ...state,
        defaultYear: payload,
      };
    },
    /* 设置标题 */
    setNavbarTitle(state, { payload }) {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          title: payload,
        },
      };
    },
    /* 设置rightContent */
    setNavbarRightContent(state, { payload }) {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          rightContent: payload,
        },
      };
    },
    setTimeline(state, { payload }) {
      const currentTime = new Date().getTime();

      const startTime = payload.startTime ? new Date(payload.startTime).getTime() : '';
      const endTime = payload.endTime ? new Date(payload.endTime).getTime() : '';
      const isForbid = currentTime < startTime || currentTime > endTime;
      let isForbidReason = '';
      if (currentTime < startTime) {
        isForbidReason = "notStart";
      } else if (currentTime > endTime) {
        isForbidReason = "end";
      }

      const paymentStartTime = payload.paymentStartTime ? new Date(payload.paymentStartTime).getTime() : '';
      // const paymentStartTime = new Date("2021-04-01 00:00:00").getTime();
      const paymentDeadline = payload.paymentDeadline ? new Date(payload.paymentDeadline).getTime() : '';
      const isForbidPay = currentTime < paymentStartTime || currentTime > paymentDeadline;

      let isForbidPayReason = '';
      if (currentTime < paymentStartTime) {
        isForbidPayReason = "notStart";
      } else if (currentTime > paymentDeadline) {
        isForbidPayReason = "end";
      }

      const isFromBuyCourse =
        location.href.indexOf('/buyCourse') != -1 ? true : false;

      if (isForbidPay && isFromBuyCourse) {
        const {learningMode} = Storage.cityConfig || {}
        state.navbar.title = learningMode == 2 ? '选课缴费' : '支付继续教育';
        state.navbar.rightContent = '';
      }
      return {
        ...state,
        timeline: payload,
        isForbid,
        isForbidReason,
        isForbidPay,
        isForbidPayReason
      };
    },
    setIsForbid(state, { payload }) {
      return {
        ...state,
        isForbid: payload.isForbid,
        isForbidReason: payload.isForbidReason
      };
    },
    setIsForbidPay(state, { payload }) {
      return {
        ...state,
        isForbidPay: payload,
      };
    },
  },
};
