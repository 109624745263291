import { getOrderRecord } from '@/services/record';
export default {
  namespace: 'record',
  state: {
    orderPageShow: 'table', // table(订单记录表格) or tabs(企业和个人开发票Tab)
    orderList: [],
  },
  effects: {
    *getOrderRecord({ payload }, { call, put }) {
      const res = yield call(getOrderRecord, payload);
      console.log('getOrderRecord == ', res);
      if (res.code == 0) {
        yield put({
          type: 'setOrderList',
          payload: res.data.orderList,
        });
      } else {
        return null;
      }
    },
  },
  reducers: {
    setOrderPageShow(state, { payload }) {
      return {
        ...state,
        orderPageShow: payload,
      };
    },
    setOrderList(state, { payload }) {
      return {
        ...state,
        orderList: payload,
      };
    },
  },
};
