import {
  userCouserList,
  categoryList,
  categoryCourseList,
  judgeCourse,
  upateChooseCourse,
  userReviewCourse,
  sitedAddress
  // cumulativeLearningTime,
} from '@/services/course';
import { deepCopy } from '@/utils/tool';
import Storage from '@/utils/Storage';
export default {
  namespace: 'course',
  state: {
    // 分类列表 - 保留原始数据不做修改
    categoryList: [],
    // 分类树
    categoryTree: {},
    categoryMenu1: { checked: '全部', items: [] },
    categoryMenu2: { checked: '全部', items: [] },
    categoryMenu3: { checked: '全部', items: [] },
    // 第四级是显示标题和表格 格式：{[title]: []}
    categoryMenu4: {},
    // 课程列表 - 保留原始数据不做修改
    courseList: [],
    // 通过筛选之后的需要页面显示的列表数据
    filterCourseList: [],
    // 是否已经购买过课程
    isPurchasedCourse: false,
    // 是否显示分类弹窗
    visibleCategoryModal: false,
    // 学习进度 - 该字段用户判断用户是否可以参加考试 , studyProgress=100才可以参加考试
    studyProgress: 0,
    // 是否完成课程评价 1是已经都评价完，0是还有没评价的
    isCourseEvaluated: 0,
    userCouserList: [],
    // 多学分情况下 课程的学习进度和评价列表
    judgeCourseList: []
  },
  effects: {
    *userCouserList({ payload }, { call, put }) {
      const res = yield call(userCouserList, payload);
      if (res.code == 0 && res.data.length > 0) {
        yield put({
          type: 'setUserCouserList',
          payload: res.data,
        });
      } else {
        yield put({
          type: 'setUserCouserList',
          payload: [],
        });
      }
      return res;
    },
    *categoryList({ payload }, { call, put }) {
      const res = yield call(categoryList, payload);
      if (res.code == 0) {
        yield put({
          type: 'setCategory',
          payload: res.data,
        });
      }
    },
    *categoryCourseList({ payload }, { call, put }) {
      const res = yield call(categoryCourseList, payload);

      if (res.code == 0) {
        yield put({
          type: 'setCourseList',
          payload: res.data,
        });
        yield put({
          type: 'setFilterCourseList',
          payload: res.data,
        });
      }
    },
    *sitedAddress({ payload }, { call, put }) {
      const res = yield call(sitedAddress, payload);
      return res;
    },
    *judgeCourse({ payload }, { call, put }) {
      const res = yield call(judgeCourse, { year: payload.year });

      const isFromBuyCourse =
        location.href.indexOf('/buyCourse') != -1 ? true : false;

      if (res.code == 0) {
        yield put({
          type: 'setIsPurchasedCourse',
          payload: true,
        });
        if (res.data) {
          yield put({
            type: 'setJudgeCourseList',
            payload: res.data,
          });
        }

        if (isFromBuyCourse) {
          yield put({
            type: 'global/setNavbarTitle',
            payload: '网上选课',
          });
          yield put({
            type: 'global/setNavbarRightContent',
            payload: '分类',
          });
        }
      } else {
        if (isFromBuyCourse) {
          const {learningMode} = Storage.cityConfig || {}
          yield put({
            type: 'global/setNavbarTitle',
            payload: learningMode == 2 ? '选课缴费' : '支付继续教育',
          });
          yield put({
            type: 'global/setNavbarRightContent',
            payload: learningMode == 2 ? '分类' : '',  // learningMode == 2 先选课 后缴费 模式
          });
        }
        yield put({
          type: 'setJudgeCourseList',
          payload: [],
        });
      }

      return res;
    },
    *upateChooseCourse({ payload }, { call, put }) {
      const res = yield call(upateChooseCourse, payload);
      return res;
    },
    *userReviewCourse({ payload }, { call, put }) {
      const res = yield call(userReviewCourse, payload);

      return res;
    },
    /* *cumulativeLearningTime({ payload }, { call, put }) {
      const res = yield call(cumulativeLearningTime, payload);

      return res;
    }, */
  },
  reducers: {
    // 设置课程分类列表
    setCategory(state, { payload }) {
      let formatCategory = formatTree(payload);

      let categoryMenu1 = {
        checked: '全部',
        items: ['全部', ...Object.keys(getCategoryMenu('category1', payload))],
      };
      let categoryMenu2 = {
        checked: '全部',
        items: ['全部', ...Object.keys(getCategoryMenu('category2', payload))],
      };
      let categoryMenu3 = {
        checked: '全部',
        items: ['全部', ...Object.keys(getCategoryMenu('category3', payload))],
      };
      return {
        ...state,
        categoryList: payload,
        categoryTree: formatCategory,
        categoryMenu1,
        categoryMenu2,
        categoryMenu3,
      };
    },
    // 设置课程分类菜单
    setCategoryMenu(state, { payload }) {
      // 重置 categoryMenu4
      state.categoryMenu4 = {};

      if (payload.menu == 'categoryMenu1') {
        state.categoryMenu1.checked = payload.checked;

        if (payload.checked == '全部') {
          state.categoryMenu2.checked = '全部';
          state.categoryMenu2.items = [
            '全部',
            ...Object.keys(getCategoryMenu('category2', state.categoryList)),
          ];

          state.categoryMenu3.checked = '全部';
          state.categoryMenu3.items = [
            '全部',
            ...Object.keys(getCategoryMenu('category3', state.categoryList)),
          ];
        } else {
          state.categoryMenu2.checked = '全部';
          state.categoryMenu2.items = [
            '全部',
            ...Object.keys(state.categoryTree[payload.checked]),
          ];

          const menu3 = state.categoryList.filter(
            item => item.category1 == payload.checked,
          );
          const newMenu3 = deepCopy(menu3);

          state.categoryMenu3.checked = '全部';
          state.categoryMenu3.items = [
            '全部',
            ...Object.keys(getCategoryMenu('category3', newMenu3)),
          ];
        }
      } else if (payload.menu == 'categoryMenu2') {
        state.categoryMenu2.checked = payload.checked;

        if (payload.checked == '全部') {
          state.categoryMenu3.checked = '全部';
          state.categoryMenu3.items = [
            '全部',
            ...Object.keys(getCategoryMenu('category3', state.categoryList)),
          ];
        } else {
          if (state.categoryMenu1.checked == '全部') {
            const menuName = Object.keys(state.categoryTree);
            let temp = [];
            menuName.map(item => {
              if (state.categoryTree[item][payload.checked]) {
                temp.push(
                  ...Object.keys(state.categoryTree[item][payload.checked]),
                );
              }
            });

            state.categoryMenu3.checked = '全部';
            state.categoryMenu3.items = ['全部', ...temp];
          } else {
            state.categoryMenu3.checked = '全部';
            state.categoryMenu3.items = [
              '全部',
              ...Object.keys(
                state.categoryTree[state.categoryMenu1.checked][
                  payload.checked
                ],
              ),
            ];
          }
        }
      } else if (payload.menu == 'categoryMenu3') {
        state.categoryMenu3.checked = payload.checked;

        if (payload.checked == '全部') {
          // 重置 categoryMenu4
          state.categoryMenu4 = {};
        } else {
          const menu4 = state.categoryList.filter(
            item => item.category3 == payload.checked,
          );

          menu4.map(item => {
            if (!state.categoryMenu4[item.category4]) {
              state.categoryMenu4[item.category4] = [];
            }
            const filterCourse = state.courseList.filter(
              course => course.courseId == item.courseId,
            );

            state.categoryMenu4[item.category4].push(...filterCourse);
          });
        }
      }

      let filterCategoryList = deepCopy(state.categoryList);

      // 从后往前过滤
      if (state.categoryMenu3.checked != '全部') {
        //没选全部，直接通过 category3 进行 筛选
        filterCategoryList = filterCategoryList.filter(
          item => item.category3 == state.categoryMenu3.checked,
        );
      } else {
        // 否则通过 category2 进行筛选
        if (state.categoryMenu2.checked != '全部') {
          // 没选全部，直接通过 category2 进行 筛选
          filterCategoryList = filterCategoryList.filter(
            item => item.category2 == state.categoryMenu2.checked,
          );
        } else {
          if (state.categoryMenu1.checked != '全部') {
            // 没选全部，直接通过 category1 进行 筛选
            filterCategoryList = filterCategoryList.filter(
              item => item.category1 == state.categoryMenu1.checked,
            );
          }
        }
      }

      const indexList = [];
      filterCategoryList.map(item => indexList.push(item.courseId));

      state.filterCourseList = state.courseList.filter(item =>
        indexList.indexOf(item.courseId) != -1 ? true : false,
      );

      return {
        ...state,
      };
    },
    // 设置课程列表
    setCourseList(state, { payload }) {
      return {
        ...state,
        courseList: payload,
      };
    },
    // 设置筛选之后课程列表
    setFilterCourseList(state, { payload }) {
      return {
        ...state,
        filterCourseList: payload,
        // 重置4级菜单
        categoryMenu4: {},
      };
    },
    // 设置是否购买课程
    setIsPurchasedCourse(state, { payload }) {
      return {
        ...state,
        isPurchasedCourse: payload,
      };
    },
    // 设置是否显示分类弹窗
    setVisibleCategoryModal(state, { payload }) {
      return {
        ...state,
        visibleCategoryModal: payload,
      };
    },
    /* // 设置学习进度
    setStudyProgress(state, { payload }) {
      return {
        ...state,
        studyProgress: payload,
      };
    },
    // 设置是否完成课程评价
    setIsCourseEvaluated(state, { payload }) {
      return {
        ...state,
        isCourseEvaluated: payload,
      };
    }, */
    // 设置多学分情况下课程判断列表
    setJudgeCourseList(state, { payload }) {
      return {
        ...state,
        judgeCourseList: payload,
      };
    },
    // 设置用户已选课程列表
    setUserCouserList(state, { payload }) {
      return {
        ...state,
        userCouserList: JSON.parse(JSON.stringify(payload)),
      };
    },
    // 搜索课程
    searchCourse(state, { payload }) {
      /**
       * 重置分类列表
       */
      state.categoryMenu1.checked = "全部";
      state.categoryMenu2.checked = '全部';
      state.categoryMenu2.items = [
        '全部',
        ...Object.keys(getCategoryMenu('category2', state.categoryList)),
      ];
      state.categoryMenu3.checked = '全部';
      state.categoryMenu3.items = [
        '全部',
        ...Object.keys(getCategoryMenu('category3', state.categoryList)),
      ];

      state.categoryMenu4 = {};

      // 通过搜索关键字过滤课程
      state.filterCourseList = state.courseList.filter(course => course.courseName.indexOf(payload) > -1)

      return {
        ...state
      };
    },
  },
};

function formatTree(list) {
  let temp = {};
  list.map(item => {
    if (!temp[item.category1]) {
      temp[item.category1] = {};
      temp[item.category1][item.category2] = {};
      temp[item.category1][item.category2][item.category3] = [];
    }
    if (!temp[item.category1]) {
      temp[item.category1] = {};
      temp[item.category1][item.category2] = {};
      temp[item.category1][item.category2][item.category3] = [];
    } else if (!temp[item.category1][item.category2]) {
      temp[item.category1][item.category2] = {};
      temp[item.category1][item.category2][item.category3] = [];
    } else if (!temp[item.category1][item.category2][item.category3]) {
      temp[item.category1][item.category2][item.category3] = [];
    }
    temp[item.category1][item.category2][item.category3].push(item);
  });

  return temp;
}
// 获取每一层及的分类菜单
function getCategoryMenu(category_level, list) {
  const temp = {};
  list.map(item => {
    temp[item[category_level]] = true;
  });

  return temp;
}
