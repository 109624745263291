import { request } from 'umi';

export async function getLessonList(payload) {
  return request('/api/course/getLessonList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function reportLearningProgress(payload) {
  // 学习进度
  return request('/api/course/reportLearningProgress', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
export async function bulletQuestion(payload) {
  // 学习进度
  return request('/api/course/bulletQuestion', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function getLessonDetail(payload) {
  // 学习进度
  return request('/api/course/getLessonDetail', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}