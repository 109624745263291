import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import './loading.less';
/* 路由级别的loading */
class LoadingRouter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="loading-wrapper">
        <div className="loading-mask"></div>
        <div className="loading-icon-box">
          <Icon type="loading" size={['lg']} className="loading-icon" />
        </div>
      </div>
    );
  }
}

export default LoadingRouter;
