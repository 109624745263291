import Storage from '@/utils/Storage';
import {
  loginByIdcard,
  loginByMobile,
  getUserInfo,
  editUserInfo,
  checkToken,
  forgetPassword,
  resetPassword,
  getVerification,
  register,
  getMobile,
  editMobile,
  verificationMobile,
  improveInformation,
  analyzeQr
} from '@/services/user';
import {
  get_token,
  faceStatus,
  get_token_for_app,
} from '@/services/faceVerification';
export default {
  namespace: 'user',
  state: {
    userInfo: {}
  },
  effects: {
    *loginByIdcard({ payload }, { call, put }) {
      const res = yield call(loginByIdcard, payload);
      return res;
    },
    *loginByMobile({ payload }, { call, put }) {
      const res = yield call(loginByMobile, payload);
      return res;
    },
    *get_token({ payload }, { call, put }) {
      const res = yield call(get_token, payload);
      return res;
    },
    *faceStatus({ payload }, { call, put }) {
      const res = yield call(faceStatus, payload);
      return res;
    },
    *get_token_for_app({ payload }, { call, put }) {
      const res = yield call(get_token_for_app, payload);
      return res;
    },
    *getUserInfo({ payload }, { call, put }) {
      const res = yield call(getUserInfo, payload);
      if (res.code == 0) {
        yield put({
          type: 'setUserInfo',
          payload: res.data,
        });
      }
      return res;
    },
    *editUserInfo({ payload }, { call, put }) {
      const res = yield call(editUserInfo, payload);
      return res;
    },
    *checkToken({ payload }, { call, put }) {
      const res = yield call(checkToken, payload);
      return res;
    },
    *forgetPassword({ payload }, { call, put }) {
      const res = yield call(forgetPassword, payload);
      return res;
    },
    *resetPassword({ payload }, { call, put }) {
      const res = yield call(resetPassword, payload);
      return res;
    },
    *getVerification({ payload }, { call, put }) {
      const res = yield call(getVerification, payload);
      return res;
    },
    *register({ payload }, { call, put }) {
      const res = yield call(register, payload);
      return res;
    },
    *getMobile({ payload }, { call, put }) {
      const res = yield call(getMobile, payload);
      if (res.code == 0) {
        yield put({
          type: 'setMobile',
          payload: res.data.mobile,
        });
      }
      return res;
    },
    *editMobile({ payload }, { call, put }) {
      const res = yield call(editMobile, payload);
      return res;
    },
    *verificationMobile({ payload }, { call, put }) {
      const res = yield call(verificationMobile, payload);
      return res;
    },
    *improveInformation({ payload }, { call, put }) {
      const res = yield call(improveInformation, payload);
      return res;
    },
    *analyzeQr({ payload }, { call, put }) {
      const res = yield call(analyzeQr, payload);
      return res;
    },
  },
  reducers: {
    setMobile(state, { payload }) {
      return {
        ...state,
        mobile: payload,
      };
    },
    setUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload,
      };
    },
  },
};
