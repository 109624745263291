import { request } from 'umi';
export async function get_token(payload) {
  return request('/api/face/h5/get_token', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 获取人脸验证结果
export async function faceStatus(payload) {
  return request('/api/user/faceStatus', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

export async function get_token_for_app(payload) {
  return request('/api/face/app/get_token', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
