import ReactDOM from 'react-dom';

/**
 * @methods formatTime 格式化时间
 * @param value 秒
 *  */
export const formatTime = value => {
  let newValue = value ? value : 0; //防止出现NaN
  let secondTime = parseInt(newValue); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime > 60) {
    //如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60);
    //获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60);
    //如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60);
      //获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60);
    }
  }

  if (hourTime < 10) {
    hourTime = `0${hourTime}`;
  }
  if (minuteTime < 10) {
    minuteTime = `0${minuteTime}`;
  }
  if (secondTime < 10) {
    secondTime = `0${secondTime}`;
  }

  return {
    // 转换 00:00:00
    formatTiming: `${hourTime}:${minuteTime}:${secondTime}`,
    // 转换成分钟
    formatMinute: parseInt(value / 60),
  };
};
/**
 * @methods getElementToParentDistance 获取元素距离父元素的的距离
 * @param childNode 孩子节点（必填）
 * @param parentNode 父级节点（选填）
 */
export const getElementToParentDistance = (childNode, parentNode) => {
  const parent = parentNode || document.documentElement;

  let oChild = ReactDOM.findDOMNode(childNode);

  let oParent = oChild.parentElement;

  let distance = parseInt(oChild.offsetTop);

  while (oParent != parent) {
    oChild = oParent;
    oParent = oParent.parentElement;
    console.log(oParent == parent);
    distance += parseInt(oChild.offsetTop);
  }

  console.log('22222 ', distance);
  return distance;
};

//判断数据类型
export const getClass = o => {
  return Object.prototype.toString.call(o).slice(8, -1);
};
// 深拷贝
export const deepCopy = obj => {
  var result,
    oClass = getClass(obj);
  if (oClass == 'Object') result = {};
  //判断传入的如果是对象，继续遍历
  else if (oClass == 'Array') result = [];
  //判断传入的如果是数组，继续遍历
  else return obj; //如果是基本数据类型就直接返回

  for (var i in obj) {
    var copy = obj[i];

    if (getClass(copy) == 'Object') result[i] = deepCopy(copy);
    //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
    else if (getClass(copy) == 'Array') result[i] = deepCopy(copy);
    //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
    else result[i] = copy; //基本数据类型则赋值给属性
  }

  return result;
};

// 保留2位小数
export const toFloat = function(value) {
  value = Math.round(parseFloat(value) * 100) / 100;

  if (value.toString().indexOf('.') < 0) {
    value = value.toString() + '.00';
  }

  return value;
};

// 字母表排序
export const alphaOrder = function(alpha) {
  return alpha
    .split('')
    .sort((a, b) => a.localeCompare(b, 'zh-Hans-CN', { sensitivity: 'accent' }))
    .join('');
};

// 获取URL中的query.param
export const getParams = () => {
  var arr1 = window.location.search.substring(1).split('&');

  var params = {};
  for (var i = 0; i < arr1.length; i++) {
    var arr2 = arr1[i].split('=');
    for (var j = 0; j < arr2.length; j++) {
      params[arr2[0]] = arr2[1];
    }
  }
  return params;
};

// 判断终端类型
export const terminalType = () => {
  var u = navigator.userAgent;
  var isIE = u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1;
  var isIE11 = u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1;
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ];
  var isWap = false;
  // 检测是Android APP
  if (u.indexOf('native_android') != -1) {
    return 'android';
  }
  // 检测IOS APP
  if (u.indexOf('native_ios') != -1) {
    return 'ios';
  }
  if (isIE || isIE11) {
    return 'ie';
  }
  // 检测是pc还是wap
  for (var v = 0; v < Agents.length; v++) {
    if (u.indexOf(Agents[v]) > 0) {
      isWap = true;
      break;
    }
  }
  if (isWap) {
    return 'wap';
  } else {
    return 'pc';
  }
};

export const checkbrowser = () => {
  var u = navigator.userAgent;
  // 检测是 weixin
  if (u.indexOf('MicroMessenger') != -1) {
    return 'weixin';
  }
  // 检测是不是苹果端
  if (u.indexOf('native_ios') != -1) {
    return 'ios';
  }
  // 检测终端浏览器与iPad
  if (u.indexOf('iPhone') != -1 || u.indexOf('iPad')) {
    return 'applebrowser';
  } else {
    return 'androidbrowser';
  }
};
export const envScience = () => {
  if (
    window.location.hostname === 'jxjy-new-wap-test.taxchina.com' ||
    window.location.origin === 'http://jxjy-new-wap-test.taxchina.com'
  ) {
    return 'test';
  } else if (
    window.location.hostname === 'jxjy-new-wap-dev.taxchina.com' ||
    window.location.origin === 'http://jxjy-new-wap-dev.taxchina.com'
  ) {
    return 'dev';
  } else if (
    window.location.hostname === 'kj-m.taxchina.com' ||
    window.location.origin === 'https://kj-m.taxchina.com'
  ) {
    return 'prod';
  } else {
    return 'dev';
  }
};

export const isCanPopQestionBrowser = () => {
  let ua = navigator.userAgent.toLowerCase();

  if (
    ua.indexOf('mqqbrowser') > -1 ||
    (ua.indexOf('sogou') > -1 && ua.indexOf('android') > -1) ||
    (ua.indexOf('baidu') > -1 && ua.indexOf('android') > -1) ||
    (ua.indexOf('qihoobrowser') > -1 && ua.indexOf('android') > -1)
  ) {
    return false
  }

  return true
}
