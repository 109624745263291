import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import expire from 'store/plugins/expire';
import CryptoJS from './cryptoJS'
class Storage {
  constructor() {
    this.store = engine.createStore([localStorage], [expire]);
  }
  set token(value) {
    value ? this.store.set('token', value) : this.store.remove('token');
  }
  get token() {
    return this.store.get('token');
  }
  set currentCity(value) {
    value
      ? this.store.set('currentCity', value)
      : this.store.remove('currentCity');
  }
  get currentCity() {
    return this.store.get('currentCity');
  }
  // oldCurrentCity 存储上一次的城市
  set oldCurrentCity(value) {
    value
      ? this.store.set('oldCurrentCity', value)
      : this.store.remove('oldCurrentCity');
  }
  get oldCurrentCity() {
    return this.store.get('oldCurrentCity');
  }
  set yearList(value) {
    value ? this.store.set('yearList', value) : this.store.remove('yearList');
  }
  get yearList() {
    return this.store.get('yearList');
  }
  set defaultYear(value) {
    value
      ? this.store.set('defaultYear', value)
      : this.store.remove('defaultYear');
  }
  get defaultYear() {
    return this.store.get('defaultYear');
  }
  set currentPage(value) {
    value
      ? this.store.set('currentPage', value)
      : this.store.remove('currentPage');
  }
  get currentPage() {
    return this.store.get('currentPage');
  }
  set examTime(value) {
    value ? this.store.set('examTime', value) : this.store.remove('examTime');
  }
  get examTime() {
    return this.store.get('examTime');
  }
  set isExaming(value) {
    value ? this.store.set('isExaming', value) : this.store.remove('isExaming');
  }
  get isExaming() {
    return this.store.get('isExaming');
  }
  // 人脸验证是否通过
  set faceAuthStatus(value) {
    value
      ? this.store.set('faceAuthStatus', value)
      : this.store.remove('faceAuthStatus');
  }
  get faceAuthStatus() {
    return this.store.get('faceAuthStatus');
  }
  // 人脸验证失败次数
  set faceAuth_failedTimes(value) {
    value
      ? this.store.set('faceAuth_failedTimes', value)
      : this.store.remove('faceAuth_failedTimes');
  }
  get faceAuth_failedTimes() {
    return this.store.get('faceAuth_failedTimes');
  }
  // 考试ID
  set examId(value) {
    value ? this.store.set('examId', value) : this.store.remove('examId');
  }
  get examId() {
    return this.store.get('examId');
  }
  // 记录用户最近一次答题信息
  set lastExamQuestion(value) {
    value
      ? this.store.set('lastExamQuestion', value)
      : this.store.remove('lastExamQuestion');
  }
  get lastExamQuestion() {
    return this.store.get('lastExamQuestion');
  }
  set examScore(value) {
    value ? this.store.set('examScore', value) : this.store.remove('examScore');
  }
  get examScore() {
    return this.store.get('examScore');
  }
  // 考试中认证的题目
  set authQuestion(value) {
    value
      ? this.store.set('authQuestion', value)
      : this.store.remove('authQuestion');
  }
  get authQuestion() {
    return this.store.get('authQuestion');
  }
  // 姓名
  set name(value) {
    value ? this.store.set('name', value) : this.store.remove('name');
  }
  get name() {
    return this.store.get('name');
  }
  // 身份证
  set identity(value) {
    value ? this.store.set('identity', value) : this.store.remove('identity');
  }
  get identity() {
    return this.store.get('identity');
  }
  // 手机号
  set mobile(value) {
    value ? this.store.set('mobile', value) : this.store.remove('mobile');
  }
  get mobile() {
    return this.store.get('mobile');
  }
  // 专业技术资格级别
  set professionalQualificationLevel(value) {
    value ? this.store.set('professionalQualificationLevel', value) : this.store.remove('professionalQualificationLevel');
  }
  get professionalQualificationLevel() {
    return this.store.get('professionalQualificationLevel');
  }
  // 密码
  set password(value) {
    const pwd = value ? CryptoJS.Encrypt(value) : '';
    value ? this.store.set('password', pwd) : this.store.remove('password');
  }
  get password() {
    const pwd = this.store.get('password') ? CryptoJS.Decrypt(this.store.get('password')) : ''
    return pwd;
  }
  // 做人脸认证需要的数据
  set authData(value) {
    value ? this.store.set('authData', value) : this.store.remove('authData');
  }
  get authData() {
    return this.store.get('authData');
  }
  // 是否调用获取人脸验证状态(faceStatus)接口
  set isOpenFaceStatus(value) {
    value
      ? this.store.set('isOpenFaceStatus', value)
      : this.store.remove('isOpenFaceStatus');
  }
  get isOpenFaceStatus() {
    return this.store.get('isOpenFaceStatus');
  }
  // 城市配置信息
  set cityConfig(value) {
    value
      ? this.store.set('cityConfig', value)
      : this.store.remove('cityConfig');
  }
  get cityConfig() {
    return this.store.get('cityConfig');
  }
  // 是否登录验证成功
  set isLoginVerifyFail(value) {
    value
      ? this.store.set('isLoginVerifyFail', value)
      : this.store.remove('isLoginVerifyFail');
  }
  get isLoginVerifyFail() {
    return this.store.get('isLoginVerifyFail');
  }
  // 从那个页面进入的auth页
  set intoAuthFrom(value) {
    value
      ? this.store.set('intoAuthFrom', value)
      : this.store.remove('intoAuthFrom');
  }
  get intoAuthFrom() {
    return this.store.get('intoAuthFrom');
  }
  set isRememberPassword(value) {
    this.store.set('isRememberPassword', value)
  }
  get isRememberPassword() {
    return this.store.get('isRememberPassword');
  }
  // 只用于购买课程页面的判断 判断是否需要停止定时器
  set isClickedWxPayBtn(value) {
    value
      ? this.store.set('isClickedWxPayBtn', value)
      : this.store.remove('isClickedWxPayBtn');
  }
  get isClickedWxPayBtn() {
    return this.store.get('isClickedWxPayBtn');
  }
  set isClickedWxPayBtnForBuyCredit(value) {
    value
      ? this.store.set('isClickedWxPayBtnForBuyCredit', value)
      : this.store.remove('isClickedWxPayBtnForBuyCredit');
  }
  get isClickedWxPayBtnForBuyCredit() {
    return this.store.get('isClickedWxPayBtnForBuyCredit');
  }
  set userCreditId(value) {
    value
      ? this.store.set('userCreditId', value)
      : this.store.remove('userCreditId');
  }
  get userCreditId() {
    return this.store.get('userCreditId');
  }
  set userCreditIdForBuyCredit(value) {
    value
      ? this.store.set('userCreditIdForBuyCredit', value)
      : this.store.remove('userCreditIdForBuyCredit');
  }
  get userCreditIdForBuyCredit() {
    return this.store.get('userCreditIdForBuyCredit');
  }
  set creditId(value) {
    value
      ? this.store.set('creditId', value)
      : this.store.remove('creditId');
  }
  get creditId() {
    return this.store.get('creditId');
  }
  set creditIdForBuyCredit(value) {
    value
      ? this.store.set('creditIdForBuyCredit', value)
      : this.store.remove('creditIdForBuyCredit');
  }
  get creditIdForBuyCredit() {
    return this.store.get('creditIdForBuyCredit');
  }
  /**
   * personalizedConfig：默认值为false，该字段主要用来处理IOS审核
   * personalizedConfig=true IOS审核菜单 
   * personalizedConfig=false 系统默认菜单
   */
  set personalizedConfig(value) {
    value
      ? this.store.set('personalizedConfig', value)
      : this.store.remove('personalizedConfig');
  }
  get personalizedConfig() {
    return this.store.get('personalizedConfig');
  }
  /**
   * 单位为毫秒
   */
  setExpire(key, value, seconds) {
    this.store.set(key, value, new Date().getTime() + 1000 * seconds);
  }
  set(key, value) {
    this.store.set(key, value);
  }
  get(key) {
    return this.store.get(key);
  }
}

export default new Storage();
