import { request } from 'umi';

// 根据当前区域及年度获取用户的选课信息接口
export async function userCouserList(payload) {
  return request('/api/course/userCouserList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 分类接口
export async function categoryList(payload) {
  return request('/api/course/categoryList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 根据课程分类，获取当前分类接口
export async function categoryCourseList(payload) {
  return request('/api/course/categoryCourseList', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 判断是否购买课程
export async function judgeCourse(payload) {
  return request('/api/course/judgeCourse', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 确认选课功能接口
export async function upateChooseCourse(payload) {
  return request('/api/course/upateChooseCourse', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
export async function sitedAddress(payload) {
  // 跳转地址
  return request('/api/questionsurvey/siteAddress', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 点评课程
export async function userReviewCourse(payload) {
  return request('/api/course/userReviewCourse', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 累计学习时长接口
/* export async function cumulativeLearningTime(payload) {
  return request('/api/course/cumulativeLearningTime', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
} */
