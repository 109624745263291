// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/data1/jenkins/workspace/jxjy-new-wap-test/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading/loadingRouter';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
    "meta": {
      "belong": "host"
    },
    "exact": true
  },
  {
    "path": "/download",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__download' */'@/pages/download'), loading: LoadingComponent}),
    "meta": {
      "belong": "host"
    },
    "exact": true
  },
  {
    "path": "/jump",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__jump' */'@/pages/jump'), loading: LoadingComponent}),
    "meta": {
      "belong": "host"
    },
    "exact": true
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent}),
        "exact": true,
        "meta": {
          "title": "登录"
        }
      }
    ]
  },
  {
    "path": "/forgetPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/forgetPassword",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__forgetPassword__index' */'@/pages/forgetPassword/index'), loading: LoadingComponent}),
        "exact": true,
        "meta": {
          "title": "忘记密码"
        }
      }
    ]
  },
  {
    "path": "/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__index' */'@/pages/register/index'), loading: LoadingComponent}),
        "exact": true,
        "meta": {
          "title": "注册"
        }
      }
    ]
  },
  {
    "path": "/commitment",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/commitment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__commitment__index' */'@/pages/commitment/index'), loading: LoadingComponent}),
        "meta": {
          "title": "承诺书"
        },
        "exact": true
      }
    ]
  },
  {
    "path": "/auth",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/auth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__auth__index' */'@/pages/auth/index'), loading: LoadingComponent}),
        "meta": {
          "title": "验证"
        },
        "exact": true
      }
    ]
  },
  {
    "path": "/finishUserInfo",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/finishUserInfo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finishUserInfo__index' */'@/pages/finishUserInfo/index'), loading: LoadingComponent}),
        "meta": {
          "title": "完善信息"
        },
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'@/layouts/BasicLayout'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "meta": {
      "belong": "city"
    },
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "redirect": "/home",
        "meta": {
          "sideMenuChecked": "home",
          "title": "首页"
        },
        "exact": true
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "home",
          "title": "首页"
        },
        "exact": true
      },
      {
        "path": "/myInfo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myInfo__index' */'@/pages/myInfo/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "myInfo",
          "title": "我的信息"
        },
        "exact": true
      },
      {
        "path": "/buyCourse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__buyCourse__index' */'@/pages/buyCourse/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "buyCourse",
          "title": "",
          "rightContent": "分类"
        },
        "exact": true
      },
      {
        "path": "/buyCredit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__buyCredit__index' */'@/pages/buyCredit/index'), loading: LoadingComponent}),
        "meta": {
          "title": "支付继续教育"
        },
        "exact": true
      },
      {
        "path": "/listenPractice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__listenPractice__index' */'@/pages/listenPractice/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "listenPractice",
          "title": "听课练习"
        },
        "exact": true
      },
      {
        "path": "/practice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__practice__index' */'@/pages/practice/index'), loading: LoadingComponent}),
        "meta": {
          "title": "学习"
        },
        "exact": true
      },
      {
        "path": "/shiting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shiting__index' */'@/pages/shiting/index'), loading: LoadingComponent}),
        "meta": {
          "title": "试听"
        },
        "exact": true
      },
      {
        "path": "/exercises",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exercises__index' */'@/pages/exercises/index'), loading: LoadingComponent}),
        "meta": {
          "title": "练习题"
        },
        "exact": true
      },
      {
        "path": "/mockTest",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mockTest__index' */'@/pages/mockTest/index'), loading: LoadingComponent}),
        "meta": {
          "title": "模拟测试"
        },
        "exact": true
      },
      {
        "path": "/errorQuestion",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__errorQuestion__index' */'@/pages/errorQuestion/index'), loading: LoadingComponent}),
        "meta": {
          "title": "我的错题"
        },
        "exact": true
      },
      {
        "path": "/examNotes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examNotes__index' */'@/pages/examNotes/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "examNotes",
          "title": "考试须知"
        },
        "exact": true
      },
      {
        "path": "/examSelect",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examSelect__index' */'@/pages/examSelect/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "examNotes",
          "title": "在线考试"
        },
        "exact": true
      },
      {
        "path": "/notice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notice__index' */'@/pages/notice/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "notice",
          "title": "学员须知"
        },
        "exact": true
      },
      {
        "path": "/onlineExam",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__onlineExam__index' */'@/pages/onlineExam/index'), loading: LoadingComponent}),
        "meta": {
          "title": "00:00:00",
          "rightContent": "交卷"
        },
        "exact": true
      },
      {
        "path": "/examResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examResult__index' */'@/pages/examResult/index'), loading: LoadingComponent}),
        "meta": {
          "title": "考试结果"
        },
        "exact": true
      },
      {
        "path": "/myRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myRecord__index' */'@/pages/myRecord/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "myRecord",
          "title": "我的记录"
        },
        "exact": true
      },
      {
        "path": "/invoice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invoice__index' */'@/pages/invoice/index'), loading: LoadingComponent}),
        "meta": {
          "title": "开票"
        },
        "exact": true
      },
      {
        "path": "/set",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__set__index' */'@/pages/set/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "set",
          "title": "设置"
        },
        "exact": true
      },
      {
        "path": "/resetPassword",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__index' */'@/pages/resetPassword/index'), loading: LoadingComponent}),
        "meta": {
          "title": "重置密码"
        },
        "exact": true
      },
      {
        "path": "/bindMobile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bindMobile__index' */'@/pages/bindMobile/index'), loading: LoadingComponent}),
        "meta": {
          "title": "绑定手机号"
        },
        "exact": true
      },
      {
        "path": "/changeMobile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__changeMobile__index' */'@/pages/changeMobile/index'), loading: LoadingComponent}),
        "meta": {
          "title": "更换手机号"
        },
        "exact": true
      },
      {
        "path": "/report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__index' */'@/pages/report/index'), loading: LoadingComponent}),
        "meta": {
          "sideMenuChecked": "report",
          "title": "网上申报"
        },
        "exact": true
      }
    ]
  },
  {
    "path": "/404",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notFound__index' */'@/pages/notFound/index'), loading: LoadingComponent}),
    "exact": true,
    "meta": {
      "title": "404"
    }
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notFound__index' */'@/pages/notFound/index'), loading: LoadingComponent})
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
