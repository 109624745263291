import { request } from 'umi';

// 获取城市设置的时间线
export async function timeline(payload) {
  return request('/api/city/timeline', {
    method: 'GET',
    params: {
      ...payload,
    },
  });
}

// 获取城市的配置信息
export async function cityConfig(payload) {
  return request('/api/city/config', {
    method: 'GET',
    params: {
      ...payload,
    },
  });
}

// 获取当前年度的学分设置信息
export async function cityCredit(payload) {
  return request('/api/city/credit', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// APP下载地址
export async function appDownloadUrl(payload) {
  return request('/api/upgrade/app', {
    method: 'GET',
    params: {
      ...payload,
    },
  });
}

// 获取当前省份下得城市信息
export async function getProviceCity(payload) {
  return request('/api/city/proviceCity', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
