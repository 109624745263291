import {
  generateTestPaper,
  getExamInfo,
  getQuestionInfoById,
  submitQuestion,
  getExamTimes,
  submitPaper,
  getFaceAuth,
  getAnnounceByCode,
  getMyIncorrectList,
  getMExercisesList,
  submitMockPaper,
  getScore,
} from '@/services/exam';

export default {
  namespace: 'exam',
  state: {
    // 存储试卷
    paper: {},
  },
  effects: {
    *generateTestPaper({ payload }, { call, put }) {
      const res = yield call(generateTestPaper, payload);
      if (res.code == 0) {
        yield put({
          type: 'setPaper',
          payload: res.data || {},
        });
      }
      return res;
    },
    *getExamInfo({ payload }, { call, put }) {
      const res = yield call(getExamInfo, payload);

      return res;
    },
    *getQuestionInfoById({ payload }, { call, put }) {
      const res = yield call(getQuestionInfoById, payload);

      return res;
    },
    *submitQuestion({ payload }, { call, put }) {
      const res = yield call(submitQuestion, payload);

      return res;
    },
    *getExamTimes({ payload }, { call, put }) {
      const res = yield call(getExamTimes, payload);

      return res;
    },
    *submitPaper({ payload }, { call, put }) {
      const res = yield call(submitPaper, payload);

      return res;
    },
    *getFaceAuth({ payload }, { call, put }) {
      const res = yield call(getFaceAuth, payload);

      return res;
    },
    *getAnnounceByCode({ payload }, { call, put }) {
      const res = yield call(getAnnounceByCode, payload);

      return res;
    },
    *getMyIncorrectList({ payload }, { call, put }) {
      const res = yield call(getMyIncorrectList, payload);

      return res;
    },
    *getMExercisesList({ payload }, { call, put }) {
      const res = yield call(getMExercisesList, payload);

      return res;
    },
    *submitMockPaper({ payload }, { call, put }) {
      const res = yield call(submitMockPaper, payload);

      return res;
    },
    *getScore({ payload }, { call, put }) {
      const res = yield call(getScore, payload);

      return res;
    },
  },
  reducers: {
    setPaper(state, { payload }) {
      return {
        ...state,
        paper: payload,
      };
    },
  },
};
