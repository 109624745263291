import { 
  getLessonList,
  reportLearningProgress,
  bulletQuestion,
  getLessonDetail 
} from '../services/practice';

const Model = {
  namespace: 'practice',
  state: {
    data: [],
  },
  effects: {
    *getLessonList({ payload }, { call, put }) {
      const res = yield call(getLessonList, payload);
      return res;
    },
    *reportLearningProgress({ payload }, { call, put }) {
      const res = yield call(reportLearningProgress, payload);
      return res;
    },
    *bulletQuestion({ payload }, { call, put }) {
      const res = yield call(bulletQuestion, payload);
      return res;
    },
    *getLessonDetail({ payload }, { call, put }) {
      const res = yield call(getLessonDetail, payload);
      return res;
    },
  },
  reducers: {

  },
};
export default Model;
