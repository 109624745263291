import { request } from 'umi';
export async function loginByIdcard(payload) {
  return request('/api/user/loginByIdcard', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
export async function loginByMobile(payload) {
  return request('/api/user/loginByMobile', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 用户详情接口
export async function getUserInfo(payload) {
  return request('/api/user/getUserInfo', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 用户信息编辑接口
export async function editUserInfo(payload) {
  return request('/api/user/editUserInfo', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 检测token是否有效
export async function checkToken(payload) {
  return request('/api/user/checkToken', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 忘记密码
export async function forgetPassword(payload) {
  return request('/api/user/forgetPassword', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 重置密码
export async function resetPassword(payload) {
  return request('/api/user/resetPassword', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 发送短信验证码
export async function getVerification(payload) {
  return request('/api/user/getVerification', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 注册
export async function register(payload) {
  return request('/api/user/fastLogin', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 获取手机号
export async function getMobile(payload) {
  return request('/api/user/getMobile', {
    method: 'GET',
    params: {
      ...payload,
    },
  });
}

// 更改用户手机号
export async function editMobile(payload) {
  return request('/api/user/editMobile', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 验证老手机号
export async function verificationMobile(payload) {
  return request('/api/user/verificationMobile', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
// 完善用户信息
export async function improveInformation(payload) {
  return request('/api/user/improveInformation', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}

// 解析二维码，获取内容
export async function analyzeQr(payload) {
  return request('/api/qrcode/analyzeQr', {
    method: 'POST',
    data: {
      ...payload,
    },
  });
}
